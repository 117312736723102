import React from 'react';
import { Container, Row, Col, Card, Modal } from "react-bootstrap"
import {injectIntl} from "gatsby-plugin-react-intl"
import logo from "../../images/notificare-actito-logo.svg"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

class QuickWinsVideos extends React.Component {

  constructor(props, context) {
    super(props, context);

    this.state = {
      currentVideo: null,
      showModal: false
    };
  }

  componentDidMount() {

    let searchParams = new URLSearchParams(document.location.search);
    if (searchParams && searchParams.has("v")) {
      this.openVideo(searchParams.get("v"));
    }

  }

  openVideo = (v) => {
    this.setState({
      currentVideo: v,
      showModal: true
    });
  }

  handleClose = () => {
    this.setState({
      currentVideo: null,
      showModal: false
    });
  }

  render() {
        return (

            <div className={`quickwins-videos`}>
                <Container>
                    <Row>
                        {this.props.content.videos.map((content, i) => {
                            return (
                                <Col key={i} lg={6}>
                                    <div className={content.alignment ? `simple-card ` + content.alignment : `simple-card`}>
                                        <Card>
                                            <a className={`card-button`} href={content.button.url} target={content.button.target} rel={`noopener`}>

                                              <div className={`card-image`}>
                                                <img alt={``} src={logo} className={`logo`} />
                                              </div>

                                              <Card.Body>
                                                {content.title &&
                                                <Card.Title>{content.title}</Card.Title>
                                                }
                                                {content.subtitle &&
                                                <Card.Subtitle className="mb-2 text-muted">{content.subtitle}</Card.Subtitle>
                                                }
                                                {content.text &&
                                                <Card.Text dangerouslySetInnerHTML={{__html: content.text}}></Card.Text>
                                                }
                                                {content.button &&
                                                <div className={`button`}>{content.button.label} <FontAwesomeIcon icon="arrow-right" /></div>
                                                }
                                              </Card.Body>
                                            </a>
                                      </Card>
                                    </div>
                                </Col>
                            )
                        })}
                    </Row>
                </Container>

                <Modal centered className={`video-modal`} show={this.state.showModal} onHide={this.handleClose}>
                    <Modal.Header closeButton>
                    </Modal.Header>
                    <Modal.Body>
                        <iframe
                          allowFullScreen={true}
                          allowscriptaccess={`always`}
                          frameBorder={0}
                          allow={`accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture`}
                          id={`videoFrame`}
                          src={`https://www.youtube-nocookie.com/embed/${this.state.currentVideo}?rel=0&modestbranding=1`}
                          title={`Quick Wins`}
                        ></iframe>
                    </Modal.Body>
                </Modal>

            </div>
        )
    }
}

export default injectIntl(QuickWinsVideos)
