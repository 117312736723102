import React from "react"
import { injectIntl } from "gatsby-plugin-react-intl"
import Layout from "../components/layout"
import Seo from "../components/seo"
import QuickWinsData from "../content/quick-wins/index.json"
import QuickWinsVideos from "../components/misc/quickwins-videos"
import HeroSubpage from "../components/heroes/hero-subpage"

const QuickWinsPage = ({ intl }) => (
    <Layout>
        <Seo lang={intl.locale}
             title={QuickWinsData[intl.locale].title}
             description={QuickWinsData[intl.locale].text}
        />
        <HeroSubpage
            theme={`dark`}
            content={QuickWinsData[intl.locale]}
        />
        <QuickWinsVideos content={QuickWinsData[intl.locale]} />
    </Layout>
)

export default injectIntl(QuickWinsPage)
